import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Typography from '@material-ui/core/Typography'
import { Button } from 'gatsby-theme-material-ui'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <Typography variant='h4' style={{ textAlign: 'center' }}>
      <SentimentVeryDissatisfiedIcon style={{ fontSize: 70 }}>filled</SentimentVeryDissatisfiedIcon>
      <br/>
      Sorry.
    </Typography>
    <Typography variant='body1'>
      I have not created this page yet.
    </Typography>
    <br/>:
    <div style={{ textAlign: 'center' }}>
      <Button href="/">Go Back Home</Button>
    </div>
  </Layout>
)

export default NotFoundPage
